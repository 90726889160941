import React, { useState } from 'react';
import { data, category } from '../data/data';
import { Link } from 'react-router-dom';
import FoodOrder from './FoodOrder';

const Food = () => {
    const [foods, setFoods] = useState(data);

    const allArray = () => setFoods(data);

    const filterByCategory = (categoryData) => {
        const foodlist = data.filter((item) => item.category === categoryData);
        setFoods(foodlist);
    };

    return (
        <div name="order" className="max-w-[1640px] m-auto px-4 py-12">
            <h1 className="text-orange-600 font-bold text-4xl text-center mb-5">Top Rated Menu Items</h1>

          {/* Graphical Marquee */}
<div className="relative overflow-hidden bg-gradient-to-r from-green-400 via-orange-200 to-pink-500 rounded-lg shadow-lg mb-5">
  <div className="flex whitespace-nowrap items-center py-3 animate-marquee">
  <span className="text-white font-bold text-2xl mx-10 flex items-center">
  🎉 First-Time Users Offer: Coupon Available for 
  <span className="text-blue-500 mx-1">50%</span> Off!
</span>
    <span className="text-white font-bold text-2xl mx-10 flex items-center">
      🥳 Bulk Order Offer: Get  <span className="text-blue-500 mx-1">25%</span> Off!
    </span>
    <span className="text-white font-bold text-2xl mx-10 flex items-center">
      🎁 Don't Miss Out on Amazing Discounts!
    </span>
  </div>
</div>


            {/* Filter Row */}
            <div className="flex flex-col lg:flex-row justify-between">
                <div>
                    <p className="font-bold text-gray-700">Filter Type</p>
                    <div className="flex justify-between flex-wrap">
                        <button
                            className="border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white m-1"
                            onClick={allArray}
                        >
                            All
                        </button>
                        {category.map((item, index) => (
                            <button
                                key={index}
                                className="border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white m-1"
                                onClick={() => filterByCategory(item.category)}
                            >
                                {item.category}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Display Foods */}
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-4">
    {foods.map((item, index) => {
        // Adjust price if category is bucketBriyani
        const adjustedPrice = item.category ===  `Rs.${item.price}`;
    

        return (
            <Link to={`/foodOrder/${item.id}`} key={index} element={<FoodOrder />}>
                <div className="border rounded-lg shadow-xl hover:scale-105 duration-300">
                    <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-[300px] object-cover rounded-t-lg"
                    />
                    <div className="flex justify-between items-center bg-orange-200 rounded-b-lg px-2 py-4">
                        <p className="font-bold text-[20px] text-[tomato]">{item.name}</p>
                        <p>
                         <span className="bg-green-500 text-white p-2 rounded-full">
                              <span className="text-xl font-bold">RS.{item.price}</span>
                            </span>
                            
                        </p>
                    </div>
                </div>
            </Link>
        );
    })}
</div>

        </div>
    );
};

export default Food;
