import Idly from '../assets/idly.jpg';
import Poori from '../assets/poori.jpg';
import Rice from '../assets/rice.jpg';
import Briyani from '../assets/briyani.jpg';
import BucketBriyani from '../assets/bucketbriyani.jpeg';
import Dosa from '../assets/dosa.jpeg';
import LemonRice from '../assets/lemonrice.jpg';
import Muthuidly from '../assets/muthuidly.jpg';
import Muthusapathi from '../assets/sapathi.jpg';
import Muthupaniyaram from '../assets/paniyaram.jpg';
import Muthudosa from '../assets/muthudosa.jpg';
import Muthuragikali from '../assets/muthuragikali.jpg';
import Muthuvegrice from '../assets/muthuvegrice.jpg';
import Malligavegrice from '../assets/malligavegrice.jpg';
import Muthuchickenrice from '../assets/muthuchickenrice.jpg';
import Muthuparotta from '../assets/muthuparotta.jpg';
import Muthunoodles from '../assets/muthunoodles.jpg';
import Muthuraagiidly from '../assets/muthuraagiidli.jpg';
import Malligameals from '../assets/malligameals.png';
import Malligavadai from '../assets/malligavadai.jpg';
import Muthukothuparotta from '../assets/muthkothuparotta.jpg';
import Muthuamlet from '../assets/muthuamlet.jpg';

/*export const data = [

    {
        id: 1,
        name: 'Idly',
        category: 'Steamed',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Idly,
        price: 20,
        description: 'Idli is a traditional South Indian dish renowned for its light, fluffy texture and nutritional benefits. Made from a fermented batter of rice and urad dal (black gram), idli is a steamed food that is both wholesome and easy to digest. The batter is poured into round molds and steamed to produce soft, cake-like discs.'
    },
    {
        id: 2,
        name: 'Dosa',
        category: 'Spoiled',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Dosa,
        price: 20,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 3,
        name: 'Poori',
        category: 'Fried Food',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Poori,
        price: 20,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 4,
        name: 'Lemon Rice',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: LemonRice,
        price: 50,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 5,
        name: 'Rice',
        category: 'Steamed',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Rice,
        price: 80,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 6,
        name: 'Briyani',
        category: 'Boiled',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Briyani,
        price: 120,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 7,
        name: 'Idly',
        category: 'Boiled',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuidly,
        price: 15,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 8,
        name: 'Sapathi',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthusapathi,
        price: 23,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 9,
        name: 'Paniyaram',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthupaniyaram,
        price: 5,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 10,
        name: 'Dosa',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthudosa,
        price: 18,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 11,
        name: 'Ragi kali',
        category: 'Spoiled',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuragikali,
        price: 30,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 12,
        name: 'Veg Rice',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuvegrice,
        price: 100,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 13,
        name: 'Veg Rice',
        category: 'Fried',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Malligavegrice,
        price: 90,
        description: 'Idli is a traditional South Indian dish renowned for its light, fluffy texture and nutritional benefits. Made from a fermented batter of rice and urad dal (black gram), idli is a steamed food that is both wholesome and easy to digest. The batter is poured into round molds and steamed to produce soft, cake-like discs.'
    },
    {
        id: 14,
        name: 'Chilli Chicken',
        category: 'Fried',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuchickenrice,
        price: 100,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 15,
        name: 'Parotta',
        category: 'Stemed',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuparotta,
        price: 20,
        description: 'Àmàlà is a Nigerian food made out of yamflour. Yams are peeled, sliced, cleaned, dried and then blended into a flour, also called elubo. Yams are white in colour but turn brown when dried; this gives àmàlà its colour. Àmàlà is from Western Africa and eaten mostly by the Yoruba people in Nigeria. This food can be consumed with ewedu (jute) and stew (made from pepper, tomato, palm oil, water). The full health benefits of this kind of meal is good for the wellbeing of humans.rich in a lot of health benefits including aiding weight loss and lowering cancer risks'
    },
    {
        id: 16,
        name: 'Noodles',
        category: 'Swallow',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthunoodles,
        price: 70,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    {
        id: 17,
        name: 'Meals',
        category: 'Spoiled',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Malligameals,
        price: 80,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    {
        id: 18,
        name: 'Vadai',
        category: 'Oil Spoiled',
        shopName: 'Malliga Mess',
        shopEmail:"yaazhbatch1@gmail.com",
        image: Malligavadai,
        price: 7,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    {
        id: 19,
        name: 'Raagi Idly',
        category: 'Swallow',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuraagiidly,
        price: 12,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    {
        id: 20,
        name: 'Kothu Parotta',
        category: 'Swallow',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthukothuparotta,
        price: 60,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    {
        id: 21,
        name: 'Amlet',
        category: 'Swallow',
        shopName: 'Muthu Mess',
        shopEmail:"rajappansellamuthu@gmail.com",
        image: Muthuamlet,
        price: 20,
        description: 'Pounded Yam is a staple in many West African homes, but it is not usually eaten by itself, it is often paired with many delicious African soups, and stews like Egusi, okra soup, Jute leaves soup (Ewedu), or Stewed Spinach (Efo Riro). If you are new to this food, I’ll explain. Pounded is quite similar to mashed potatoes but it has a more doughy consistency. It belongs to a group of food we refer to as swallow because of the way we eat it. We cut out a morsel from the meal,  then we form an indentation on it with the thumb. Then, we scoop some stew or soup over it and we eat it.'
    },
    
]*/

export const data = [

  //  {
      //  id: 1,
       // name: 'Briyani',
       // category: 'Briyani',
      //  shopName: 'Aykaruppu Kidai Virunthu',
      //  shopEmail: "aykaruppukidaivirundhu@gmail.com",
      //  offer:'50',
      //  image: Briyani,
      //  price: 120,
       // offerPrice : 60,
       //description: 'A delightful feast featuring Chicken Biryani with tender, flavorful leg pieces cooked in aromatic basmati rice and Indian spices, accompanied by a rich and spicy gravy to enhance the experience. The meal is perfectly complemented with crispy and tangy Chicken 65, offering a burst of spices in every bite. For a refreshing touch, theres creamy Onion Raita with finely chopped onions and a hint of cumin. Finally, indulge in the sweet and creamy Carrot Payasam, a dessert made from grated carrots, milk, ghee, and garnished with nuts and cardamom, leaving you with a satisfying end to the meal'
  //  },
 {
        id: 1,
       name: 'Bucket Briyani 1 KG',
      category: 'bucketBriyani',
       shopName: 'Aykaruppu Kidai Virunthu',
       shopEmail: "aykaruppukidaivirundhu@gmail.com",
       offer:'50',
        image: BucketBriyani,
         price: 700,
        offerPrice : 75,
        description: 'A delightful feast featuring Chicken Biryani with tender, flavorful leg pieces cooked in aromatic basmati rice and Indian spices, accompanied by a rich and spicy gravy to enhance the experience. The meal is perfectly complemented with crispy and tangy Chicken 65, offering a burst of spices in every bite. For a refreshing touch, theres creamy Onion Raita with finely chopped onions and a hint of cumin. Finally, indulge in the sweet and creamy Carrot Payasam, a dessert made from grated carrots, milk, ghee, and garnished with nuts and cardamom, leaving you with a satisfying end to the meal'
    }

]
export const category = [
   // {
      //  id: 1,
      //  category: 'Briyani',
        
    //},
{
       id: 1,
       category: 'Bucket Briyani',
        
  },

]

export const categories = [
    {
        id: 1,
        name: 'Fast Food',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/FastFood_BrowseHome@3x.png',
    },
    {
        id: 2,
        name: 'Pizza',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Pizza_BrowseHome@3x.png',
    },
    {
        id: 3,
        name: 'Wings',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Wings_BrowseHome@3x.png',
    },
    {
        id: 4,
        name: 'Indian',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Indian_BrowseHome@3x.png',
    },
    {
        id: 5,
        name: 'Latest Deals',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Deals_BrowseHome@3x.png',
    },
    {
        id: 6,
        name: 'Restaurant Rewards',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/RestoRewards_BrowseHome@3x.png',
    },
    {
        id: 7,
        name: 'Best Overall',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/TopEats_Browse%20Home@3x.png',
    },
    {
        id: 8,
        name: 'Shipped Free',
        image:
            'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Placeholder_Plates@3x.png',
    },
];